 <template>
  <div class="listimg com">
    <ListTitle :category="category"></ListTitle>
    <div class="_box ">
      <div class="_title">
        扶贫镜头
      </div>
      <div class="_info">
        <div class="_card" v-for="item,i in list" :key="i" @click="goto(item.id)">
          <div class="_img">
            <img :src="item.image" alt="">
          </div>
          <div class="_disc">
            {{item.title}}
          </div>
        </div>
      </div>
      <div class="_page">
        <el-pagination background layout="prev, pager, next"  :page-size='take' :total="total" @current-change='next'>
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import ListTitle from "../components/ListTitle.vue";
export default {
  name: "home",
  components: { ListTitle },
  created() {
      this.id = this.$route.query.id;
      if (this.$route.query.id) {
        this.getCategory();
      }
  },
  // watch: {
  //   id: {
  //     handler(newVal) {
  //       this.getCategory();
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      id: false,
      category: false,
      page: 0,
      total: 0,
      list: [],
      take:20,
    };
  },
  methods: {
    next(e) {
      this.page = e;
      this.getList();
    },
    goto(e) {
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push("/article?id=" + e + "&category=" + this.category.id);
    },
    getCategory() {
      this.$api.get({
        url: "index/category",
        data: { id: this.id },
        success: (res) => {
          this.category = res.data.category;
          this.getList();
        },
      });
    },
    getList(){
       this.$api.get({
        url: "index/list",
        data: { category: this.category.id, page: this.page, key: this.keys,take:this.take },
        success: (res) => {
          this.total = res.data.total;
          this.list = res.data.data;
        },
      });
    }
  },
};
</script>

<style lang="scss">
</style>
